import AdminFormDataPage from "../views/home/AdminFormPage";
import Homepage from "../views/home";
import Login from "../components/UI/LoginPage";
import { Navigate } from "react-router";
import React from "react";
import { Routes } from "../states/states";

// import { Navigate } from "react-router";

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const isAuthenticated = localStorage.getItem("token");
  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }
  return children;
};
const CommonRoutes: Array<Routes> = [
  {
    path: "/form-dashboard",
    element: (
      <RequireAuth>
        <Homepage />
      </RequireAuth>
    ),
  },
  {
    path: "/admin-form-data",
    element: (
      <RequireAuth>
        <AdminFormDataPage />
      </RequireAuth>
    ),
  },
  //   {
  //     path: "/headzer",
  //     element: <AppHeader />,
  //   },
  {
    path: "/",
    element: <Login />,
  },

  //   {
  //     path: "/dailyPayout",
  //     element: (
  //       <RequireAuth>
  //         <DailyPayout />
  //       </RequireAuth>
  //     ),
  //   },
  //   {
  //     path: "/multiclientPayout",
  //     element: (
  //       <RequireAuth>
  //         <MultiClientPayout />
  //       </RequireAuth>
  //     ),
  //   },
  //   {
  //     path: "/payoutRelease",
  //     element: <PayoutRelease />,
  //   },
  //   {
  //     path: "/sideBar",
  //     element: <Sidebar />,
  //   },
];
export const Router: Array<Routes> = [
  ...CommonRoutes,
  /**
   *@description
   * Url to Embeded in Iframe
   *
   */
  // {
  //   path: "", // test route
  //   element: <Header />,
  //   children: [
  //     ...CommonRoutes,
  //     {
  //       path: "*", // test route
  //       element: <div>NOT FOUND</div>,
  //     },
  //   ],
  // },
];
