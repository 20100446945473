import axios, { AxiosInstance, AxiosResponse } from "axios";

import { FormDTO } from "../@types/DTO/FormSubmission";
import { Statuses } from "../@types/Status";
import { approveOrRejectDTO } from "../@types/DTO/ApproveOrReject";
import { iPaginatedResponse } from "../@types/DRO/iPaginatedResponse";
import { sendForApprovalDTO } from "../@types/DTO/SendForApproval";

export class FormSubmissionApi {
  public static api = new FormSubmissionApi();
  private instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      baseURL: "https://sun-rmt-back.bcykal.com",
      //   baseURL: process.env.REACT_APP_ZYPP_UPLOAD_API_URL,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getToken() {
    return localStorage.getItem("token");
  }

  async submitForm(data: FormDTO): Promise<AxiosResponse<any>> {
    const token = this.getToken();
    if (!token) {
      throw new Error("No token found");
    }

    return this.instance.post("/api/tickets/create", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  async getAllQuotationPendingTickets(
    pageSize: number = 10,
    pageNumber: number = 1
  ): Promise<AxiosResponse<iPaginatedResponse<any>>> {
    const token = this.getToken();
    if (!token) {
      throw new Error("No token found");
    }

    return this.instance.get(`/api/tickets/get/all`, {
      params: {
        pageSize,
        pageNumber,
        statusName: Statuses.QUOTATION_PENDING,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  async getAllInApprovalTickets(
    pageSize: number = 10,
    pageNumber: number = 1
  ): Promise<AxiosResponse<iPaginatedResponse<any>>> {
    const token = this.getToken();
    if (!token) {
      throw new Error("No token found");
    }

    return this.instance.get(`/api/tickets/get/all`, {
      params: {
        pageSize,
        pageNumber,
        statusName: Statuses.PENDING_APPROVAL,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  async getAllApprovedTickets(
    pageSize: number = 10,
    pageNumber: number = 1
  ): Promise<AxiosResponse<iPaginatedResponse<any>>> {
    const token = this.getToken();
    if (!token) {
      throw new Error("No token found");
    }

    return this.instance.get(`/api/tickets/get/all`, {
      params: {
        pageSize,
        pageNumber,
        statusName: Statuses.APPROVED,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  async getAllRejectedTickets(
    pageSize: number = 10,
    pageNumber: number = 1
  ): Promise<AxiosResponse<iPaginatedResponse<any>>> {
    const token = this.getToken();
    if (!token) {
      throw new Error("No token found");
    }

    return this.instance.get(`/api/tickets/get/all`, {
      params: {
        pageSize,
        pageNumber,
        statusName: Statuses.REJECTED,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  async getAllAccidentalTickets(
    pageSize: number = 10,
    pageNumber: number = 1
  ): Promise<AxiosResponse<iPaginatedResponse<any>>> {
    const token = this.getToken();
    if (!token) {
      throw new Error("No token found");
    }

    return this.instance.get(`/api/tickets/get/all`, {
      params: {
        pageSize,
        pageNumber,
        statusName: Statuses.ACCIDENTAL,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  async sendForApproval(
    ticketId: number,
    data: sendForApprovalDTO
  ): Promise<AxiosResponse<sendForApprovalDTO>> {
    const token = this.getToken();
    if (!token) {
      throw new Error("No token found");
    }

    return this.instance.patch(
      `/api/tickets/send-for-approval/${ticketId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  async approveOrReject(
    ticketId: number,
    data: approveOrRejectDTO
  ): Promise<AxiosResponse<approveOrRejectDTO>> {
    const token = this.getToken();
    if (!token) {
      throw new Error("No token found");
    }

    return this.instance.patch(
      `/api/tickets/approve-or-reject/${ticketId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }
}
