import {
  Alert,
  AppBar,
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Snackbar,
  Switch,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import { FormSubmissionApi } from "../../api/FormDataApi";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";

// import CloseIcon from "@mui/icons-material/Close";




const lightTheme = createTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
  palette: {
    mode: "light",
  },
});

const darkTheme = createTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
  palette: {
    mode: "dark",
  },
});

const Homepage = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    chassisNumber: "",
    vehicleRefNo: "",
    typeOfVOR: "",
    hubLocation: "",
    vehicleRepair: "",
    issueDescription: "",
    suggestedParts: [] as string[],
    partsUnderWarranty: [] as string[],
    optionalRemarks: "",
  });
  const [formErrors, setFormErrors] = useState({
    email: "",
    chassisNumber: "",
    vehicleRefNo: "",
    issueDescription: "",
  });
  const [submissionSuccess, setSubmissionSuccess] = useState<string | null>(
    null
  );
  const [submissionError, setSubmissionError] = useState<string | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const errors: any = {};
    if (!formData.email) errors.email = "Email is required.";
    if (!formData.chassisNumber)
      errors.chassisNumber = "Chassis Number is required.";
    if (!formData.vehicleRefNo)
      errors.vehicleRefNo = "Vehicle Ref Number is required.";
    if (!formData.issueDescription)
      errors.issueDescription = "Issue Description is required.";

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    try {
      const response = await FormSubmissionApi.api.submitForm(formData);
      console.log("Form submitted successfully:", response.data);
      setSubmissionSuccess("Form submitted successfully!");
      setTimeout(() => {
        setSubmissionSuccess(null);
        window.location.reload();
      }, 2000);
    } catch (error: any) {
      console.error("Error submitting form:", error);
      const errorMessage =
        error.response?.data?.message ||
        "Failed to submit the form. Please try again.";
      setSubmissionError(errorMessage);
    }
  };

  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSubmissionSuccess(null);
    setSubmissionError(null);
  };

  return (
    <>
      <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              Vehicle Repair Form
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={darkMode}
                  onChange={() => setDarkMode(!darkMode)}
                />
              }
              label="Dark Mode"
            />
            <div className="flex items-center">
              <Link to="/">
                <Tooltip title="Logout" onClick={handleLogout}>
                  <img src="/assets/logout.png" alt="" className="h-6" />
                </Tooltip>
              </Link>
            </div>
          </Toolbar>
        </AppBar>
        <Container
          sx={{
            width: "100%",
            padding: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <Paper
            elevation={3}
            sx={{
              p: 4,
              width: "75%",
              border: "green",
            }}
          >
            <Box
              component="form"
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 3,
              }}
              onSubmit={handleSubmit}
            >
              <Typography variant="h3">
                North and South Zypp 2W Customer VOR logging
              </Typography>
              <Typography
                variant="body1"
                component="p"
                sx={{ fontWeight: "bold" }}
              >
                By Filling the Form, you are accepting that,
              </Typography>
              <Typography>
                <ol>
                  <li>
                    1. Date on which the form is filled will be considered as
                    date of Intimation of Vehicle Repair Request and details of
                    Vehicle not registered in this form by Customer will not be
                    qualifying for Repair attention by SMPL and shall not be
                    taken inside the SMPL/KMS Hubs
                  </li>
                  <li>
                    2. Vehicle shall be considered as VOR only after customer
                    approvals against the repair quote in the shared Google
                    sheets and If Customer waits for Insurance Claim, the
                    vehicle will not be deemed “VOR” but either “Abandoned” or
                    “Customer waiting for Insurance”. No repairs will be started
                    without approval from Customer in the shared Google sheets
                  </li>
                  <li>
                    3. Vehicles made ready after repairs ( Ready for delivery-
                    RFD ) as per approved quotation amount by customer needs to
                    be taken in Customer Custody within 24 hrs and vehicle idle
                    time post RFD ,shall not be considered as down time on
                    account of SMPL.
                  </li>
                </ol>
              </Typography>
              <Typography variant="body1" component="p">
                <span className="font-bold">Guidelines:</span>
                <ol>
                  <li>
                    1. Only Full length Chassis number is considered as a Valid
                    vehicle id (EB number, Reg No. are for Reference only)
                  </li>
                  <li>
                    2. Hub location should be the vehicles current hub location
                    where which the vehicle is waiting for repair
                  </li>
                  <li>
                    3. Only Response from valid Email Id is accounted (Email ID
                    details to be shared with SMPL supervisors)
                  </li>
                </ol>
              </Typography>
              <TextField
                label="Email ID"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                fullWidth
                error={!!formErrors.email}
                helperText={formErrors.email}
              />
              <TextField
                label="Chassis Number"
                name="chassisNumber"
                value={formData.chassisNumber}
                onChange={handleChange}
                required
                fullWidth
                error={!!formErrors.chassisNumber}
                helperText={formErrors.chassisNumber}
              />
              <TextField
                label="Vehicle Ref Number"
                name="vehicleRefNo"
                value={formData.vehicleRefNo}
                onChange={handleChange}
                required
                fullWidth
                error={!!formErrors.vehicleRefNo}
                helperText={formErrors.vehicleRefNo}
              />

              <FormControl component="fieldset">
                <FormLabel component="legend" className="text-left">
                  Type for VOR(Reason for off Road)
                </FormLabel>
                <RadioGroup
                  name="typeOfVOR"
                  value={formData.typeOfVOR}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="Theft"
                    control={<Radio />}
                    label="Theft"
                  />
                  <FormControlLabel
                    value="Accident"
                    control={<Radio />}
                    label="Accident"
                  />
                  <FormControlLabel
                    value="Running Repairs"
                    control={<Radio />}
                    label="Running Repairs"
                  />
                  <FormControlLabel
                    value="Scheduled Maintenance"
                    control={<Radio />}
                    label="Scheduled Maintenance"
                  />
                  <FormControlLabel
                    value="Impound(Govt Authority)"
                    control={<Radio />}
                    label="Impound(Govt Authority)"
                  />
                  <FormControlLabel
                    value="Breakdown"
                    control={<Radio />}
                    label="Breakdown"
                  />
                  <FormControlLabel
                    value="Battery Missing"
                    control={<Radio />}
                    label="Battery Missing"
                  />
                </RadioGroup>
              </FormControl>

              <FormControl component="fieldset">
                <FormLabel
                  component="legend"
                  className="text-left font-bold text-3xl"
                >
                  Hub Location
                </FormLabel>
                <RadioGroup
                  name="hubLocation"
                  value={formData.hubLocation}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="Sahibabad"
                    control={<Radio />}
                    label="Sahibabad"
                  />
                  <FormControlLabel
                    value="Greater Noida"
                    control={<Radio />}
                    label="Greater Noida"
                  />
                  <FormControlLabel
                    value="Sec 113 Bajghera"
                    control={<Radio />}
                    label="Sec 113 Bajghera"
                  />
                  <FormControlLabel
                    value="Sec 52 smart hub"
                    control={<Radio />}
                    label="Sec 52 smart hub"
                  />
                  <FormControlLabel
                    value="Chhatarpur"
                    control={<Radio />}
                    label="Chhatarpur"
                  />
                  <FormControlLabel
                    value="Mundka"
                    control={<Radio />}
                    label="Mundka"
                  />
                  <FormControlLabel
                    value="Sec 69 Sahibabad"
                    control={<Radio />}
                    label="Sec 69 Sahibabad"
                  />
                  <FormControlLabel
                    value="Zypp whitefiled"
                    control={<Radio />}
                    label="Zypp whitefiled"
                  />
                  <FormControlLabel
                    value="Zypp yelahanka"
                    control={<Radio />}
                    label="Zypp yelahanka"
                  />
                  <FormControlLabel
                    value="Auto Terminal SMPL Dealership"
                    control={<Radio />}
                    label="Auto Terminal SMPL Dealership"
                  />
                </RadioGroup>
              </FormControl>

              <FormControl component="fieldset">
                <FormLabel component="legend" className="text-left">
                  We (customer) are repairing this vehicle on our own
                </FormLabel>
                <RadioGroup
                  name="vehicleRepair"
                  value={formData.vehicleRepair}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>

              <TextField
                label="Issue Description"
                name="issueDescription"
                value={formData.issueDescription}
                onChange={handleChange}
                multiline
                rows={4}
                required
                fullWidth
                error={!!formErrors.issueDescription}
                helperText={formErrors.issueDescription}
              />
              <TextField
                label="Suggestions of Parts to be Displayed"
                name="suggestedParts"
                value={formData.suggestedParts}
                onChange={handleChange}
                multiline
                rows={2}
                fullWidth
              />
              <TextField
                label="Suggestions of Parts to be Claimed Under Warranty"
                name="partsUnderWarranty"
                value={formData.partsUnderWarranty}
                onChange={handleChange}
                multiline
                rows={2}
                fullWidth
              />
              <TextField
                label="Optional Remarks"
                name="optionalRemarks"
                value={formData.optionalRemarks}
                onChange={handleChange}
                multiline
                rows={2}
                fullWidth
              />

              <Button variant="contained" color="primary" type="submit">
                Submit
              </Button>
              <Snackbar
                open={!!submissionSuccess}
                autoHideDuration={2000}
                onClose={handleSnackbarClose}
              >
                <Alert
                  onClose={handleSnackbarClose}
                  severity="success"
                  sx={{ width: "100%", bgcolor: "green", color: "white" }}
                >
                  {submissionSuccess}
                </Alert>
              </Snackbar>
              <Snackbar
                open={!!submissionError}
                autoHideDuration={2000}
                onClose={handleSnackbarClose}
              >
                <Alert
                  onClose={handleSnackbarClose}
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  {submissionError}
                </Alert>
              </Snackbar>
            </Box>
          </Paper>
        </Container>
      </ThemeProvider>
    </>
  );
};

export default Homepage;
