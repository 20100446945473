import {
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import MailOutline from "@mui/icons-material/MailOutline";
import Password from "@mui/icons-material/LockOutlined";
import { SunRmtApi } from "../../../api/LoginApi";
import { useNavigate } from "react-router";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("ADVISOR");
  const [loginError, setLoginError] = useState<string | null>(null);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = (e: any) => {
    e.preventDefault();
    handleLogin();
  };

  const handleLogin = async () => {
    setLoader(true);
    try {
      const response = await SunRmtApi.api.onlineUserAuthentication(
        email,
        password,
        role
      );
      setLoader(false);
      if (response.data && response.data.token) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("role", role); 
        if (role === "ADVISOR") {
          navigate("/form-dashboard");
        } else {
          navigate("/admin-form-data");
        }
      } else {
        setLoginError(
          "Access Denied: You do not have permission to access this page."
        );
      }
    } catch (error) {
      console.error("Error:", error);
      setLoader(false);
      setLoginError("Invalid Credentials or Network Error");
    }
  };
  if (
    loginError ===
    "Access Denied: You do not have permission to access this page."
  ) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Typography variant="h5" color="error">
          {loginError}
        </Typography>
      </div>
    );
  }

  return (
    <>
      <div className="flex justify-center">
        <form onSubmit={handleSubmit} className="w-2/5 pt-16">
          <Grid container spacing={3} className="w-full flex">
            <Grid item xs={12}>
              <p className="text-center font-bold text-3xl text-gray-500">
                Welcome!
              </p>
              <p className="text-center mb-8 mt-2 text-gray-500">
                Log In to your account
              </p>
              <Stack spacing={1}>
                <InputLabel className="text-left" htmlFor="email">
                  Enter Username
                </InputLabel>
                <TextField
                  fullWidth
                  id="emailInstant"
                  name="emailInstant"
                  placeholder="Enter email address"
                  className="text-slate-300"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        className="text-slate-300"
                      >
                        <MailOutline />
                      </InputAdornment>
                    ),
                  }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel className="text-left" htmlFor="password">
                  Enter Password
                </InputLabel>
                <TextField
                  fullWidth
                  id="passwordInstant"
                  name="passwordInstant"
                  placeholder="Enter your password"
                  type="password"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Password />
                      </InputAdornment>
                    ),
                  }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel className="text-left" htmlFor="role">
                  Select Role
                </InputLabel>
                <Select
                  fullWidth
                  id="role"
                  name="role"
                  value={role}
                  onChange={(e) => setRole(e.target.value as string)}
                >
                  <MenuItem value="ADVISOR">ADVISOR</MenuItem>
                  <MenuItem value="ADMIN">ADMIN</MenuItem>
                  <MenuItem value="SUPER_ADMIN">SUPER ADMIN</MenuItem>
                </Select>
              </Stack>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "28px" }}>
              {loader ? (
                <Button
                  sx={{
                    backgroundColor: "#00CD5B",
                    "&:hover": {
                      backgroundColor: "#63dd99",
                    },
                  }}
                  className="bg-[#00CD5B] hover:bg-[#63dd99]"
                  fullWidth
                  size="large"
                  variant="contained"
                  type="submit"
                >
                  <CircularProgress sx={{ color: "white" }} />
                </Button>
              ) : (
                <Button
                  sx={{
                    backgroundColor: "#00CD5B",
                    "&:hover": {
                      backgroundColor: "#63dd99",
                    },
                  }}
                  className="bg-[#00CD5B] hover:bg-[#63dd99]"
                  fullWidth
                  size="large"
                  variant="contained"
                  type="submit"
                  onSubmit={handleSubmit}
                >
                  Login
                </Button>
              )}
            </Grid>
            {loginError && (
              <Grid item xs={12}>
                <Typography variant="body1" color="error">
                  {loginError}
                </Typography>
              </Grid>
            )}
          </Grid>
        </form>
      </div>
    </>
  );
};

export default Login;
