/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  Alert,
  Box,
  Button,
  IconButton,
  InputLabel,
  Modal,
  Snackbar,
  TextField,
} from "@mui/material";
import React, { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { FormSubmissionApi } from "../../../api/FormDataApi";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 850,
  borderRadius: 2,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  py: 1,
  px: 2,
};

export default function AddApprovalPendingModal({
  row,
  ticketId,
}: {
  row: any;
  ticketId: number;
}) {
  const [open, setOpen] = React.useState(false);
  const [comments, setComments] = useState<string>("");
  const [formData, setFormData] = useState({
    status: "",
    rideNumber: "",
    rideEndName: "",
    penaltyDebtAmount: 0,
    approvalAmount: 1,
    reasonForRejection: "",
  });

  // State to hold the status

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setComments("");
  };
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [SnackbarMessage, setSnackbarMessage] = useState("");
  const [submissionError, setSubmissionError] = useState<string | null>(null);
  const [submissionSuccess, setSubmissionSuccess] = useState<string | null>(
    null
  );
  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowErrorSnackbar(false);
    setShowSuccessSnackbar(false);
    window.location.reload();
  };
  const handleComments = (e: any) => {
    setComments(e.target.value);
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await FormSubmissionApi.api.approveOrReject(
        ticketId,
        formData
      );
      console.log("Form submitted successfully:", response.data);
      setSubmissionSuccess("Form submitted successfully!");
      setSnackbarMessage("Form submitted successfully!");
      setShowSuccessSnackbar(true);
      setTimeout(() => {
        handleClose();
      }, 3000); //
    } catch (error: any) {
      console.error("Error submitting form:", error);
      setSubmissionError(
        error.message || "Failed to submit the form. Please try again."
      );
      setSnackbarMessage(
        error.message || "Failed to submit the form. Please try again."
      );
      setShowErrorSnackbar(true);
    }
  };

  return (
    <div>
     <Button
        sx={{
          color:
            localStorage.getItem("role") === "ADMIN" ? "black" : "white",
          bgcolor:
            localStorage.getItem("role") === "ADMIN" ? "gray" : "#171F21",
          borderRadius: "50px",
          textTransform: "none",
          py: 1,
          px: 3,
          "&:hover": {
            bgcolor:
              localStorage.getItem("role") === "ADMIN"
                ? "gray"
                : "#171F2180",
          },
        }}
        onClick={handleOpen}
        disabled={localStorage.getItem("role") === "ADMIN"}
      >
        Add Details
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ borderRadius: "10px" }}
      >
        <Box sx={style}>
          <div className="p-4">
            <div className="flex items-center justify-between mb-4">
              <div className="font-semibold font-inter text-xl text-black">
                Add Details
              </div>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className="px-6">
              <div className="flex items-center pb-4">
                <div className="w-1/3 pr-4">
                  <TextField
                    label="Rider Number"
                    name="rideNumber"
                    id="standard-size-small"
                    size="medium"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    fullWidth
                    sx={{ my: 2 }}
                    value={formData.rideNumber}
                    onChange={handleChange}
                  />
                </div>
                <div className="w-1/3 pr-4">
                  <TextField
                    label="Ride END Name/Penalty Debit by Name"
                    name="rideEndName"
                    id="standard-size-small"
                    type="text"
                    size="medium"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    fullWidth
                    sx={{ my: 2 }}
                    value={formData.rideEndName}
                    onChange={handleChange}
                  />
                </div>
                <div className="w-1/3 pr-4">
                  <TextField
                    label="Penalty Debit amount by Hub Team"
                    name="penaltyDebtAmount"
                    id="standard-size-small"
                    size="medium"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    fullWidth
                    sx={{ my: 2 }}
                    value={formData.penaltyDebtAmount}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="flex items-center pb-5">
                <div className="w-1/3 pr-4">
                  <InputLabel
                    shrink={true}
                    style={{
                      color: "#00000099",
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    Customer status
                  </InputLabel>
                  <div className="flex">
                    <div className="border-[1px] border-[#00000099] rounded px-1 mr-2">
                      <select
                        name="status"
                        id="status"
                        className="text-[#727597] text-sm font-normal font-inter px-1 py-0.5 min-w-[220px]"
                        value={formData.status}
                        onChange={handleChange}
                      >
                        <option value="APPROVED">APPROVED</option>
                        <option value="REJECTED">REJECTED</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="w-1/3 pr-4">
                  <TextField
                    label="Approved By"
                    name="rideEndName" //change to approved by
                    id="standard-size-small"
                    type="text"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="medium"
                    variant="standard"
                    fullWidth
                    value={formData.rideEndName}
                    onChange={handleChange}
                  />
                </div>
                <div className="w-1/3 pr-4">
                  <TextField
                    label="Approval Amount"
                    id="standard-size-small"
                    type="text"
                    name="approvalAmount"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="medium"
                    variant="standard"
                    fullWidth
                    value={formData.approvalAmount}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="flex justify-between mt-3 items-center pb-5">
                <div className="w-1/3 pr-4">
                  <TextField
                    label="Reason for Rejection"
                    id="standard-size-small"
                    name="reasonForRejection"
                    type="text"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="medium"
                    variant="standard"
                    value={formData.reasonForRejection}
                    onChange={handleChange}
                    fullWidth
                  />
                </div>
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  sx={{
                    color: "white",
                    bgcolor: "#171F21",
                    borderRadius: "50px",
                    textTransform: "none",
                    py: 1,
                    px: 6,
                    "&:hover": { bgcolor: "#171F2180" },
                  }}
                >
                  Update Details
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Snackbar
        open={showSuccessSnackbar}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {SnackbarMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={showErrorSnackbar}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {SnackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
