import { Box, Paper } from "@mui/material";

import React from "react";

const extractStrokeColor = (backgroundColor: string) => {
  const rgbColor = backgroundColor.slice(0, -2);
  return rgbColor;
};

interface DashboardCardProps {
  heading: string;
  backgroundColor: string;
  count?: number;
  comparison: string;
  imgSrc: string;
  increment: boolean;
  isSelected: boolean;
}

const DashboardCard: React.FC<DashboardCardProps> = ({
  heading,
  backgroundColor,
  count = 0,
  comparison,
  imgSrc,
  increment,
  isSelected,
}) => {
  // const textColor = increment ? "#00CB60" : "#F94932";
  const strokeColor = extractStrokeColor(backgroundColor);

  return (
    <>
      <Box
        sx={{
          "& > :not(style)": {
            m: 1,
            width: "100%",
            height: "100%",
          },
        }}
      >
        <Paper
          elevation={6}
          sx={{
            boxShadow: isSelected
              ? `0px 7px 29px 0px ${backgroundColor}`
              : "0px 7px 29px 0px #64646F33",
            // border: isSelected ? `2px solid ${strokeColor}` : "none",
            borderRadius: "8px",
          }}
        >
          <div className="flex items-start">
            <div className="mt-2 ml-3 mb-2">
              <svg
                width="3"
                height="75"
                viewBox="0 0 4 119"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 2L1.99999 117"
                  stroke={strokeColor}
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </div>
            <div className="w-full cursor-pointer">
              <div className="flex justify-between items-start pt-1 pb-2 px-2 text-wrap items-end">
                <p className="text-[#727597] font-semibold text-lg font-inter text-wrap">
                  {heading}
                </p>
                <img
                  src={imgSrc}
                  alt=""
                  className="p-1"
                  style={{ backgroundColor: backgroundColor, width: "24px" }}
                />
              </div>
              <p className="px-2 text-left font-inter text-3xl font-semibold text-[#1E1E1E]">
                {count}
              </p>
            </div>
          </div>
        </Paper>
      </Box>
    </>
  );
};

export default DashboardCard;
