/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  Alert,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import AddDetailsQuotation from "../Modals/AddDetailsQuotationModal";
import { FormSubmissionApi } from "../../../api/FormDataApi";

export default function QuotationPendingTable() {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<string>("1");
  const [quotationPendingData, setQuotationPendingData] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const pageRange = 5;

  const pageCount = Math.ceil(totalCount / rowsPerPage);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } =
          await FormSubmissionApi.api.getAllQuotationPendingTickets(
            rowsPerPage,
            page + 1
          );
        setQuotationPendingData(response.data);
        setTotalCount(response.totalPages);
      } catch (error) {
        console.error("Error fetching tickets:", error);
        setShowSnackbar(true);
      }
    };

    fetchData();
  }, [page, rowsPerPage]);

  const handlePageChange = (newPage: number) => {
    const newPageNumber = Math.max(0, Math.min(newPage, pageCount - 1));
    setPage(newPageNumber);
  };

  const renderPageNumbers = () => {
    const pagesToShow = Math.min(pageCount, pageRange);
    const startPage = Math.max(
      0,
      Math.min(page - Math.floor(pageRange / 2), pageCount - pagesToShow)
    );

    return Array.from({ length: pagesToShow }, (_, index) => {
      const pageNumber = startPage + index;
      return (
        <span
          key={index}
          onClick={() => handlePageChange(pageNumber)}
          className={`pagination-number ${
            page === pageNumber ? "active" : "unactive"
          }`}
          style={{ marginLeft: "6px", cursor: "pointer" }}
        >
          {pageNumber + 1}
        </span>
      );
    });
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };

  return (
    <>
      <>
        <TableContainer>
          <div className="flex items-center justify-between py-4 px-3 border-slate-200 border-solid">
            <div className="flex">
              <div className="border-2 rounded-2xl px-2 mt-1 mr-2">
                <label className="text-[#727597] text-sm font-normal font-inter">
                  Status :{" "}
                </label>
                <select
                  name="orderStatus"
                  id="orderStatus"
                  className="text-[#727597] text-sm font-normal font-inter px-1 py-1 min-w-[150px]"
                  value={selectedStatus}
                  onChange={(e) => setSelectedStatus(e.target.value)}
                >
                  <option value="1">All</option>
                  <option value="0">Temporary</option>
                  <option value="-1">Permanent</option>
                </select>
              </div>
            </div>
          </div>
          <hr />
          <Table style={{ overflowX: "auto", width: "200%" }}>
            <TableHead>
              <TableRow sx={{ bgcolor: "#EAFFF9" }}>
                <TableCell
                  style={{
                    color: "#414141",
                    fontSize: "14px",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  Timestamp
                </TableCell>
                <TableCell
                  style={{
                    color: "#414141",
                    fontSize: "14px",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  Email address
                </TableCell>
                <TableCell
                  style={{
                    color: "#414141",
                    fontSize: "14px",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  Chassis Number (Full length, 16 or 17 digit only)
                </TableCell>
                <TableCell
                  style={{
                    color: "#414141",
                    fontSize: "14px",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  Vehicle Ref Number (EB number, Reg No, Ref No. etc)
                </TableCell>
                <TableCell
                  style={{
                    color: "#414141",
                    fontSize: "14px",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  Type of VOR (Reason for off road)
                </TableCell>
                <TableCell
                  style={{
                    color: "#414141",
                    fontSize: "14px",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  Hub Location
                </TableCell>
                <TableCell
                  style={{
                    color: "#414141",
                    fontSize: "14px",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  Issue Description
                </TableCell>
                <TableCell
                  style={{
                    color: "#414141",
                    fontSize: "14px",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  Remarks (Optional)
                </TableCell>
                <TableCell
                  style={{
                    color: "#414141",
                    fontSize: "14px",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  we (customer) are repairing this vehicle on our own
                </TableCell>
                <TableCell
                  style={{
                    color: "#414141",
                    fontSize: "14px",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  Suggestion of Parts to be REPLACED
                </TableCell>
                <TableCell
                  style={{
                    color: "#414141",
                    fontSize: "14px",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  Suggestion of Parts to be REPAIRED
                </TableCell>
                <TableCell
                  style={{
                    color: "#414141",
                    fontSize: "14px",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  Suggestion of Parts to be CLAIMED UNDER WARRANTY
                </TableCell>
                <TableCell
                  style={{
                    color: "#414141",
                    fontSize: "14px",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {quotationPendingData?.map((row: any, index: any) => (
                <React.Fragment key={index}>
                  <TableRow key={index}>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {row.ticketDetails.createdAt}
                    </TableCell>

                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {row.ticketDetails.email}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {row.ticketDetails.chassisNumber}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {row.ticketDetails.vehicleRefNo}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {row.ticketDetails.typeOfVOR}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {row.ticketDetails.hubLocation}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {row.ticketDetails.issueDescription}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {row.ticketDetails.optionalRemarks}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {row.ticketDetails.vehicleRepair}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {row.ticketDetails.suggestedParts}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {row.ticketDetails.suggestedParts}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {row.ticketDetails.partsUnderWarranty}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "14px",
                        fontWeight: "400",
                        minWidth: "160px",
                      }}
                    >
                      <AddDetailsQuotation row={row} ticketId={row.ticketId} />
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="pt-4 pb-2 pr-5">
          <div
            className="pagination"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <div className="flex">
              <img
                src="/assets/icons/leftArrow.svg"
                alt=""
                onClick={() => handlePageChange(page - 1)}
              />
              {renderPageNumbers()}
              <img
                src="/assets/icons/rightArrow.svg"
                alt=""
                onClick={() => handlePageChange(page + 1)}
                className="ml-1 mr-6"
              />
            </div>
          </div>
        </div>
        <Snackbar
          open={showSnackbar}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          <Alert sx={{ width: "100%" }} onClose={handleClose} severity="error">
            No data found
          </Alert>
        </Snackbar>
      </>
    </>
  );
}
