import axios, { AxiosInstance, AxiosResponse } from "axios";

import { LoginRespone } from "../@types/DRO/LoginResponse";

export class SunRmtApi {
  public static api = new SunRmtApi();
  private instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      baseURL: "https://sun-rmt-back.bcykal.com",
      //   baseURL: process.env.REACT_APP_ZYPP_UPLOAD_API_URL,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
  onlineUserAuthentication(
    email: string,
    password: string,
    role: string
  ): Promise<AxiosResponse<LoginRespone>> {
    return axios.post<LoginRespone>(
      "https://sun-rmt-back.bcykal.com/api/auth/login",
      {
        email,
        password,
        role,
      }
    );
  }
  getToken() {
    return localStorage.getItem("token");
  }
}
