/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */

import {
    Alert,
    Chip,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { FormSubmissionApi } from "../../../api/FormDataApi";

export default function AccidentalTable() {
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState<string>("1");
    const [accidentalData, setAccidentalData] = useState<any>([]);
    const [totalCount, setTotalCount] = useState(accidentalData.length);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const pageRange = 5;
  
    const pageCount = Math.ceil(totalCount / rowsPerPage);
  
    const handlePageChange = (newPage: number) => {
      const newPageNumber = Math.max(0, Math.min(newPage, pageCount - 1));
      setPage(newPageNumber);
    };
  
    const renderPageNumbers = () => {
      const pagesToShow = Math.min(pageCount, pageRange);
      const startPage = Math.max(
        0,
        Math.min(page - Math.floor(pageRange / 2), pageCount - pagesToShow)
      );
  
      return Array.from({ length: pagesToShow }, (_, index) => {
        const pageNumber = startPage + index;
        return (
          <span
            key={index}
            onClick={() => handlePageChange(pageNumber)}
            className={`pagination-number ${
              page === pageNumber ? "active" : "unactive"
            }`}
            style={{ marginLeft: "6px", cursor: "pointer" }}
          >
            {pageNumber + 1}
          </span>
        );
      });
    };
  
    const handleClose = (
      event?: React.SyntheticEvent | Event,
      reason?: string
    ) => {
      if (reason === "clickaway") {
        return;
      }
  
      setShowSnackbar(false);
    };
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const { data: response } =
            await FormSubmissionApi.api.getAllAccidentalTickets(
              rowsPerPage,
              page + 1
            );
          setAccidentalData(response.data);
          setTotalCount(response.totalPages);
        } catch (error) {
          console.error("Error fetching tickets:", error);
          setShowSnackbar(true);
        }
      };
  
      fetchData();
    }, [page, rowsPerPage]);
  
    return (
      <>
        <>
          <TableContainer>
            <div className="flex items-center justify-between py-4 px-3 border-slate-200 border-solid">
              <div className="flex">
                <div className="border-2 rounded-2xl px-2 mt-1 mr-2">
                  <label className="text-[#727597] text-sm font-normal font-inter">
                    Status :{" "}
                  </label>
                  <select
                    name="orderStatus"
                    id="orderStatus"
                    className="text-[#727597] text-sm font-normal font-inter px-1 py-1 min-w-[150px]"
                    value={selectedStatus}
                    onChange={(e) => setSelectedStatus(e.target.value)}
                  >
                    <option value="1">All</option>
                    <option value="0">Temporary</option>
                    <option value="-1">Permanent</option>
                  </select>
                </div>
              </div>
            </div>
            <hr />
            <Table style={{ overflowX: "auto", width: "400%" }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "14px",
                      fontWeight: "600",
                      backgroundColor: "#EAFFF9",
                    }}
                  >
                    Timestamp
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "14px",
                      fontWeight: "600",
                      backgroundColor: "#EAFFF9",
                    }}
                  >
                    Email address
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "14px",
                      fontWeight: "600",
                      backgroundColor: "#EAFFF9",
                    }}
                  >
                    Chassis Number (Full length, 16 or 17 digit only)
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "14px",
                      fontWeight: "600",
                      backgroundColor: "#EAFFF9",
                    }}
                  >
                    Vehicle Ref Number (EB number, Reg No, Ref No. etc)
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "14px",
                      fontWeight: "600",
                      backgroundColor: "#EAFFF9",
                    }}
                  >
                    Type of VOR (Reason for off road)
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "14px",
                      fontWeight: "600",
                      backgroundColor: "#EAFFF9",
                    }}
                  >
                    Hub Location
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "14px",
                      fontWeight: "600",
                      backgroundColor: "#EAFFF9",
                    }}
                  >
                    Issue Description
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "14px",
                      fontWeight: "600",
                      backgroundColor: "#EAFFF9",
                    }}
                  >
                    Remarks (Optional)
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "14px",
                      fontWeight: "600",
                      backgroundColor: "#EAFFF9",
                    }}
                  >
                    we (customer) are repairing this vehicle on our own
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "14px",
                      fontWeight: "600",
                      backgroundColor: "#EAFFF9",
                    }}
                  >
                    Suggestion of Parts to be REPLACED
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "14px",
                      fontWeight: "600",
                      backgroundColor: "#EAFFF9",
                    }}
                  >
                    Suggestion of Parts to be REPAIRED
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "14px",
                      fontWeight: "600",
                      backgroundColor: "#EAFFF9",
                      borderRight: "1px #06B681 solid",
                    }}
                  >
                    Suggestion of Parts to be CLAIMED UNDER WARRANTY
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "14px",
                      fontWeight: "600",
                      backgroundColor: "#FFE8E5",
                    }}
                  >
                    Job card Number
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "14px",
                      fontWeight: "600",
                      backgroundColor: "#FFE8E5",
                    }}
                  >
                    Job card opening Date / Inspection Date
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "14px",
                      fontWeight: "600",
                      backgroundColor: "#FFE8E5",
                    }}
                  >
                    Estimation Provided (Amount)
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "14px",
                      fontWeight: "600",
                      backgroundColor: "#FFE8E5",
                    }}
                  >
                    Calculated Indicative without GST price
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "14px",
                      fontWeight: "600",
                      backgroundColor: "#FFE8E5",
                    }}
                  >
                    Quotation Pdf
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "14px",
                      fontWeight: "600",
                      backgroundColor: "#FFE8E5",
                    }}
                  >
                    Remarks for Quote not available Cases
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "14px",
                      fontWeight: "600",
                      backgroundColor: "#FFE8E5",
                    }}
                  >
                    SMPL Remarks (customer self repair)
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "14px",
                      fontWeight: "600",
                      backgroundColor: "#FFE8E5",
                      borderRight: "1px #06B681 solid",
                    }}
                  >
                    Broad Pending Reason
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "14px",
                      fontWeight: "600",
                      backgroundColor: "#E4DFFF",
                    }}
                  >
                    Rider Number
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "14px",
                      fontWeight: "600",
                      backgroundColor: "#E4DFFF",
                    }}
                  >
                    Ride END Name/ Penalty Debit by Name
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "14px",
                      fontWeight: "600",
                      backgroundColor: "#E4DFFF",
                    }}
                  >
                    Penalty Debit amount by Hub Team
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "14px",
                      fontWeight: "600",
                      backgroundColor: "#E4DFFF",
                    }}
                  >
                    Approved By
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "14px",
                      fontWeight: "600",
                      backgroundColor: "#E4DFFF",
                    }}
                  >
                    Customer status
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "14px",
                      fontWeight: "600",
                      backgroundColor: "#E4DFFF",
                    }}
                  >
                    Approval Date
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "14px",
                      fontWeight: "600",
                      backgroundColor: "#E4DFFF",
                    }}
                  >
                    Approval Amount
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "14px",
                      fontWeight: "600",
                      backgroundColor: "#E4DFFF",
                    }}
                  >
                    Reason for Rejection
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {accidentalData?.map((row: any, index: any) => (
                  <React.Fragment key={index}>
                    <TableRow key={index}>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.ticketDetails.createdAt}
                      </TableCell>
  
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.ticketDetails.email}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.ticketDetails.chassisNumber}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.ticketDetails.vehicleRefNo}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.ticketDetails.typeOfVOR}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.ticketDetails.hubLocation}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.issueDescription}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.remarks}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.repairOwn}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.partsReplaced}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.partsRepaired}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                          borderRight: "1px #06B681 solid",
                        }}
                      >
                        {row.partsClaimedUnderWarranty}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.jobCardNo}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.jobCardDate}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.estimationAmount}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.calculativeIndicative}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        <img src="/assets/document-icon.svg" />
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        <Chip
                          label={`${row.remarksNotAvailableCases}`}
                          size="small"
                          style={{
                            backgroundColor: "#C2CCDE",
                            color: "#171F21",
                            paddingRight: "10px",
                            paddingLeft: "10px",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.smplRemarks}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                          borderRight: "1px #06B681 solid",
                        }}
                      >
                        <Chip
                          label={`${row.broadPendingReason}`}
                          size="small"
                          style={{
                            backgroundColor: "#C2CCDE",
                            color: "#171F21",
                            paddingRight: "10px",
                            paddingLeft: "10px",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.riderNo}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.penaltyDebitName}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.penaltyDebitAmount}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        <Chip
                          label={`${row.approvedBy}`}
                          size="small"
                          style={{
                            backgroundColor: "#06B681",
                            color: "#fff",
                            paddingRight: "10px",
                            paddingLeft: "10px",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        <Chip
                          label={`${row.customerStatus}`}
                          size="small"
                          style={{
                            backgroundColor: "#06B681",
                            color: "#fff",
                            paddingRight: "10px",
                            paddingLeft: "10px",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.approvalDate}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.approvalAmount}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.ticketDetails.reasonForRejection}
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="pt-4 pb-2 pr-5">
            <div
              className="pagination"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <div className="flex">
                <img
                  src="/assets/icons/leftArrow.svg"
                  alt=""
                  onClick={() => handlePageChange(page - 1)}
                />
                {renderPageNumbers()}
                <img
                  src="/assets/icons/rightArrow.svg"
                  alt=""
                  onClick={() => handlePageChange(page + 1)}
                  className="ml-1 mr-6"
                />
              </div>
            </div>
          </div>
          <Snackbar
            open={showSnackbar}
            autoHideDuration={2000}
            onClose={handleClose}
          >
            <Alert sx={{ width: "100%" }} onClose={handleClose} severity="error">
              No data found
            </Alert>
          </Snackbar>
        </>
      </>
    );
  }
  