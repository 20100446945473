/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  Alert,
  Box,
  Button,
  IconButton,
  InputLabel,
  Modal,
  Snackbar,
  TextField,
} from "@mui/material";
import React, { useRef, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { FormSubmissionApi } from "../../../api/FormDataApi";
import axios from "axios";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 850,
  borderRadius: 2,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  py: 1,
  px: 2,
};

export default function AddDetailsQuotation({
  row,
  ticketId,
}: {
  row: any;
  ticketId: number;
}) {
  const [open, setOpen] = React.useState(false);
  const [comments, setComments] = useState<string>("");
  const [formData, setFormData] = useState({
    jobCardNumber: null,
    inspectionDate: "",
    calculatedIndicativeWithoutGST: null,
    customerStatus: "",
    smlAmount: null,
    boardPendingReason: "",
    // quotationLink: "",
  });
  // State to hold the status

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setComments("");
  };
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [SnackbarMessage, setSnackbarMessage] = useState("");
  const [submissionError, setSubmissionError] = useState<string | null>(null);
  const [submissionSuccess, setSubmissionSuccess] = useState<string | null>(
    null
  );
  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowErrorSnackbar(false);
    setShowSuccessSnackbar(false);
    // window.location.reload();
  };
  const handleComments = (e: any) => {
    setComments(e.target.value);
  };
  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
    }
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!selectedFile) {
      setSubmissionError("Please select a file to upload.");
      return;
    }

    const formDataFile = new FormData();
    formDataFile.append("pdf", selectedFile);

    try {
      // First API call to upload the file
      const fileUploadResponse = await axios.post(
        "https://data.bcykal.com/document_ocr/pdf/sun_pdf",
        formDataFile,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const responseData = fileUploadResponse.data;
      setFormData((prevData) => ({
        ...prevData,
        jobCardNumber: responseData["Job Card Number"],
        inspectionDate: responseData["Date"],
        calculatedIndicativeWithoutGST: responseData["Parts"],
      }));

      // Second API call to send for approval
      const approvalResponse = await FormSubmissionApi.api.sendForApproval(
        ticketId,
        formData
      );

      console.log("Form submitted successfully:", approvalResponse.data);
      setSubmissionSuccess("Form submitted successfully!");
      setShowSuccessSnackbar(true);
      setSnackbarMessage("Form submitted successfully!");
      setTimeout(() => {
        handleClose();
      }, 2000);
    } catch (error) {
      console.error("Error submitting form:", error);
      setSubmissionError("Failed to submit the form. Please try again.");
      setShowErrorSnackbar(true);
      setSnackbarMessage("Failed to submit the form. Please try again.");
    }
  };
  return (
    <div>
      <Button
        sx={{
          color:
            localStorage.getItem("role") === "SUPER_ADMIN" ? "black" : "white",
          bgcolor:
            localStorage.getItem("role") === "SUPER_ADMIN" ? "gray" : "#171F21",
          borderRadius: "50px",
          textTransform: "none",
          py: 1,
          px: 3,
          "&:hover": {
            bgcolor:
              localStorage.getItem("role") === "SUPER_ADMIN"
                ? "gray"
                : "#171F2180",
          },
        }}
        onClick={handleOpen}
        disabled={localStorage.getItem("role") === "SUPER_ADMIN"}
      >
        Add Details
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ borderRadius: "10px" }}
        onSubmit={handleSubmit}
      >
        <Box sx={style}>
          <div className="p-4">
            <div className="flex items-center justify-between mb-4">
              <div className="font-semibold font-inter text-xl text-black">
                Add Details
              </div>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className="px-6">
              <div className="flex items-center pb-4">
                <div className="w-1/3 pr-4">
                  <TextField
                    label="Job card Number"
                    name="jobCardNumber"
                    id="standard-size-small"
                    size="medium"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    fullWidth
                    sx={{ my: 2 }}
                    value={formData.jobCardNumber}
                    onChange={handleChange}
                  />
                </div>
                <div className="w-1/3 pr-4">
                  <TextField
                    label="Job card opening Date / Inspection Date"
                    name="inspectionDate"
                    id="standard-size-small"
                    type="text"
                    size="medium"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    fullWidth
                    sx={{ my: 2 }}
                    value={formData.inspectionDate}
                    onChange={handleChange}
                  />
                </div>
                <div className="w-1/3 pr-4">
                  <TextField
                    label="Calculated Indicative without GST price"
                    name="calculatedIndicativeWithoutGST"
                    id="standard-size-small"
                    size="medium"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    fullWidth
                    sx={{ my: 2 }}
                    value={formData.calculatedIndicativeWithoutGST}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="flex items-center pb-5">
                <div className="w-1/3 pr-4">
                  <InputLabel
                    shrink={true}
                    style={{
                      color: "#00000099",
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    Select Status
                  </InputLabel>
                  <div className="flex">
                    <div className="border-[1px] border-[#00000099] rounded px-1 mr-2">
                      <select
                        name="customerStatus"
                        id="customerStatus"
                        className="text-[#727597] text-sm font-normal font-inter px-1 py-0.5 min-w-[220px]"
                        value={formData.customerStatus}
                        onChange={handleChange}
                      >
                        <option value="1">All</option>
                        <option value="0">Temporary</option>
                        <option value="-1">Permanent</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="w-1/3 pr-4">
                  <TextField
                    label="SMPL Remarks (customer self repair)"
                    name="smlAmount"
                    id="standard-size-small"
                    type="text"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="medium"
                    variant="standard"
                    fullWidth
                    value={formData.smlAmount}
                    onChange={handleChange}
                  />
                </div>
                <div className="w-1/3">
                  <InputLabel
                    shrink={true}
                    style={{
                      color: "#00000099",
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    Broad Pending Reason
                  </InputLabel>
                  <div className="flex">
                    <div className="border-[1px] border-[#00000099] rounded px-1 mr-2">
                      <select
                        name="boardPendingReason"
                        id="boardPendingReason"
                        className="text-[#727597] text-sm font-normal font-inter px-1 py-0.5 min-w-[220px]"
                        value={formData.boardPendingReason}
                        onChange={handleChange}
                      >
                        <option value="1">All</option>
                        <option value="0">Temporary</option>
                        <option value="-1">Permanent</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-between mt-3 items-center pb-5">
                <div className="w-1/3 pr-4">
                  <p className="mr-2 font-semibold">Quotation Pdf:</p>
                  {/* <TextField
                    label="Upload Quotation Link"
                    id="standard-size-small"
                    name="quotationLink"
                    type="text"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={formData.quotationLink}
                    onChange={handleChange}
                    size="medium"
                    variant="standard"
                    fullWidth
                  /> */}
                  <TextField
                    type={"file"}
                    name="quotationLink"
                    size="small"
                    required
                    inputProps={{
                      accept: ".pdf",
                    }}
                    onChange={handleFileChange}
                  />
                </div>
                <Button
                  variant="contained"
                  type="submit"
                  onClick={handleSubmit}
                  disabled={localStorage.getItem("role") === "SUPER_ADMIN"}
                  sx={{
                    color: "white",
                    bgcolor: "#171F21",
                    borderRadius: "50px",
                    textTransform: "none",
                    py: 1,
                    px: 6,
                    "&:hover": { bgcolor: "#171F2180" },
                  }}
                >
                  Update Details
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Snackbar
        open={showSuccessSnackbar}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {SnackbarMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={showErrorSnackbar}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {SnackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
