/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { Breadcrumbs, Tooltip, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";

import AccidentalTable from "../../components/layouts/Table/AccidentalTable";
import ApprovalPendingTable from "../../components/layouts/Table/ApprovalPendingTable";
import ApprovedTable from "../../components/layouts/Table/ApprovedTable";
import DashboardCard from "../../components/layouts/TableCard";
import { FormSubmissionApi } from "../../api/FormDataApi";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import QuotationPendingTable from "../../components/layouts/Table/QuotationPendingTable";
import QuotationPendingfTable from "../../components/layouts/Table/QuotationPendingTable";
import RejectedTable from "../../components/layouts/Table/RejectedTable";
import { Roles } from "../../@types/ROLES";
import dummyData from "../../data/dummyData.json";

interface CardData {
  heading: string;
  backgroundColor: string;
  count: number;
  comparison: string;
  imgSrc: string;
  increment: boolean;
  id: string;
}

const AdminFormDataPage: React.FC = () => {
  const [selectedCard, setSelectedCard] = useState<string | null>("quotation");

  const handleCardClick = (cardId: string) => {
    setSelectedCard((prevCard) => (prevCard === cardId ? null : cardId));
  };

  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/sun-admin-login");
  };
  const [allCounts, setAllCounts] = useState({
    quotation: 0,
    pending: 0,
    approved: 0,
    rejected: 0,
  });

  const role = localStorage.getItem("role");

  const cardData: CardData[] = [
    {
      heading: "Quotation Pending",
      backgroundColor: "#FF725E33",
      count: allCounts.quotation,
      comparison: "50",
      imgSrc: "/assets/quotation-icon.svg",
      increment: false,
      id: "quotation",
    },
    {
      heading: "Approval Pending",
      backgroundColor: "#FFB84633",
      count: allCounts.pending,
      comparison: "50",
      imgSrc: "/assets/approval-pending-icon.svg",
      increment: true,
      id: "pending",
    },
    {
      heading: "Approved",
      backgroundColor: "#06B68133",
      count: allCounts.approved,
      comparison: "50",
      imgSrc: "/assets/approved-icon.svg",
      increment: false,
      id: "approved",
    },
    {
      heading: "Rejected",
      backgroundColor: "#F9493233",
      count: allCounts.rejected,
      comparison: "50",
      imgSrc: "/assets/rejected-icon.svg",
      increment: true,
      id: "rejected",
    },
    {
      heading: "Accidental",
      backgroundColor: "#7B61FF33",
      count: 0,
      comparison: "50",
      imgSrc: "/assets/accidental-icon.svg",
      increment: false,
      id: "accidental",
    },
  ];
  useEffect(() => {
    Promise.all([
      FormSubmissionApi.api.getAllInApprovalTickets(1),
      FormSubmissionApi.api.getAllQuotationPendingTickets(1),
      FormSubmissionApi.api.getAllAccidentalTickets(1),
      FormSubmissionApi.api.getAllApprovedTickets(1),
      FormSubmissionApi.api.getAllRejectedTickets(1),
    ]).then((res) => {
      setAllCounts(() => {
        return {
          quotation: res[1].data.totalCount,
          pending: res[0].data.totalCount,
          approved: res[3].data.totalCount,
          rejected: res[4].data.totalCount,
          accidental: res[2].data.totalCount,
        };
      });
    });
  }, []);

  // useEffect(() => {
  //   const handleTouchMove = (event: TouchEvent) => {
  //     if (event.touches.length > 1) {
  //       event.preventDefault();
  //     }
  //   };
  
  //   document.addEventListener("touchmove", handleTouchMove, { passive: false });
  
  //   return () => {
  //     document.removeEventListener("touchmove", handleTouchMove);
  //   };
  // }, []);

  return (
    <div className="flex">
      <div className="w-full">
        <div className="pb-4 pr-4 pl-2 mt-6">
          <div className="flex justify-between mt-8">
            <div className="font-medium text-left text-[32px] text-[#1E1E1E] font-inter">
            {role === "ADMIN" ? "Sun Admin" : role === "SUPER_ADMIN" ? "Zypp Admin" : ""}  
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
                sx={{ mt: 4 }}
              ></Breadcrumbs>
            </div>
            <div className="flex items-center ml-auto">
              <Link to="/">
                <Tooltip title="Logout" onClick={handleLogout}>
                  <img src="/assets/logout.png" alt="" className="h-6" />
                </Tooltip>
              </Link>
            </div>

            <div className="mt-1">
              {/* <Button
                className="mb-2"
                variant="contained"
                sx={{
                  textTransform: "none",
                  px: 2,
                  mb: 2,
                  bgcolor: "#0079FF",
                  "&:hover": { bgcolor: "#0077F" },
                  fontSize: "18px",
                  borderRadius: "30px",
                }}
                onClick={handleCreateOrder}
              >
                <img
                  src="/assets/whitePlusIcon.svg"
                  alt=""
                  className="mr-2"
                />
                Create Order
              </Button> */}
            </div>
          </div>
        </div>
        <div className="flex mb-1 justify-between pr-5">
          {cardData.map((card) => (
            <div
              key={card.id}
              className="w-full mr-2"
              onClick={() => handleCardClick(card.id)}
            >
              <DashboardCard
                heading={card.heading}
                backgroundColor={card.backgroundColor}
                count={card.count}
                comparison={card.comparison}
                imgSrc={card.imgSrc}
                increment={card.increment}
                isSelected={selectedCard === card.id}
              />
            </div>
          ))}
        </div>
        <div
          className="ml-2 w-[98%]"
          style={{
            boxShadow: "0px 7px 29px 0px #64646F33",
            borderRadius: "8px",
          }}
        >
          {selectedCard && (
            <div>
              {selectedCard === "quotation" && (
                <div>
                  <QuotationPendingTable />
                </div>
              )}
              {selectedCard === "pending" && (
                <div>
                  <ApprovalPendingTable />
                </div>
              )}
              {selectedCard === "approved" && (
                <div>
                  <ApprovedTable />
                </div>
              )}
              {selectedCard === "rejected" && (
                <div>
                  <RejectedTable />
                </div>
              )}
              {selectedCard === "accidental" && (
                <div>
                  <AccidentalTable />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminFormDataPage;
